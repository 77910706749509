import md5 from 'blueimp-md5';
import { Base64 } from 'js-base64';

/**
 * @desc md5 加密
 * @param {string} str
 * @returns {string}
 */
export const md5Encode = str => md5(str);

/**
 * @desc base64 加密
 * @param {string} str
 * @returns {string}
 */
export const base64Encode = str => Base64.encode(str);

/**
 * @desc base64 解密
 * @param {string} str
 * @returns {string}
 */
export const base64Decode = str => Base64.decode(str);
