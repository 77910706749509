<template>
  <div class="login">
    <div class="login-container">
      <!-- 登录标题 -->
      <div class="login-title">
        <p class="title-text">游戏运营统计系统</p>
      </div>

      <!-- 登录表单 -->
      <el-form class="login-form" ref="form" :model="model" :rules="rules">
        <!-- 手机号 -->
        <el-form-item prop="telephone">
          <el-input
            v-model.trim="model.telephone"
            placeholder="请输入手机号"
            prefix-icon="el-icon-user"
            clearable
            @keyup.native.enter="handleLoginClick"
          />
        </el-form-item>

        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            v-model.trim="model.password"
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            clearable
            show-password
            @keyup.native.enter="handleLoginClick"
            @change="handlePasswordChangedManually"
          />
        </el-form-item>

        <!-- 密码 -->
        <el-form-item>
          <label class="form-remember">
            <el-checkbox
              v-model="model.remember"
              :true-label="1"
              :false-label="0"
            />
            <span class="remember-text">记住密码</span>
          </label>
        </el-form-item>

        <!-- 登录按钮 -->
        <el-form-item>
          <el-button
            class="form-login"
            type="primary"
            :loading="loading"
            @click="handleLoginClick"
          >
            登 录
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="login-footer">
      <p class="footer-desc">
        <span>Copyright&copy;2022</span>
        <span>YiJin</span>
        <span>All Rights Reserved.</span>
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import {
    getCachedLoginData,
    setCachedLoginData,
    removeCachedLoginData,
  } from '../../lib/login';
  import { md5Encode } from '../../util/encrypt.js';
  import { login } from '../../api/login';
  import { setToken } from '../../lib/token';
  import getAppBootstrapData from '../../lib/get-app-bootstrap-data';

  export default {
    name: 'login',
    data() {
      return {
        model: null,
        // 用户手动地输入/修改了密码
        pwdChangedManually: false,
        rules: {
          telephone: [
            { required: true, message: '请输入手机号', trigger: 'change' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'change' },
          ],
        },
        loading: false,
      };
    },
    computed: {
      ...mapGetters(['userHomeRouteName']),

      // 登录后重定向到的全路径
      redirectFullPath() {
        const { query: { redirect = '' } = {} } = this.$route;

        return decodeURIComponent(redirect);
      },
      // 缓存在本地的登录数据
      cachedLoginData() {
        return getCachedLoginData() || {};
      },
      // 缓存的登录数据是否记住密码
      isCachedRememberPwd() {
        const { remember = 0 } = this.cachedLoginData;

        return !!remember;
      },
      // 缓存的登录数据是否未超时
      isCachedNotExpired() {
        const { expires = 0 } = this.cachedLoginData;

        return expires >= Date.now();
      },
      // 密码加密后的登录数据
      encryptedModel() {
        const { telephone, password, remember } = this.model;

        return {
          telephone,
          password:
            this.isCachedRememberPwd &&
            this.isCachedNotExpired &&
            !this.pwdChangedManually
              ? password
              : md5Encode(password),
          remember,
        };
      },
    },
    created() {
      const initialModel =
        this.isCachedRememberPwd && this.isCachedNotExpired
          ? this.cachedLoginData
          : null;

      this.initModel(initialModel);
      this.isCachedNotExpired || removeCachedLoginData();
    },
    methods: {
      initModel(data = {}) {
        const { telephone = '', password = '', remember = 0 } = data || {};

        this.model = {
          telephone,
          password,
          remember,
        };
      },
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      async saveModel() {
        const { telephone, password } = this.encryptedModel;

        return await login({
          telephone,
          password,
        });
      },
      // 用户手动地输入了密码，而不是系统自动地读取了记住的密码
      handlePasswordChangedManually() {
        this.pwdChangedManually = true;
      },
      // 登录按钮单击
      async handleLoginClick() {
        const valid = await this.validateModel();

        if (!valid) return;

        this.loading = true;

        const res = await this.saveModel();

        if (!res) {
          this.loading = false;

          return;
        }

        const { data: token } = res;
        const { remember = 0 } = this.model;

        setToken(token);
        remember
          ? setCachedLoginData(this.encryptedModel)
          : removeCachedLoginData();

        // 获取应用启动数据
        await getAppBootstrapData();

        this.loading = false;

        // 重定向到的路由参数
        const redirectRouteParams = this.redirectFullPath
          ? { path: this.redirectFullPath }
          : { name: this.userHomeRouteName };

        this.$router.replace(redirectRouteParams);
      },
    },
  };
</script>

<style scoped lang="scss">
  .login {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
      width: 50%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100%;
    }

    &::before {
      left: 0;
      background-image: url('../../assets/img/login-bg-left.png');
    }

    &::after {
      right: 0;
      background-image: url('../../assets/img/login-bg-right.png');
    }

    .login-container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      width: 385px;

      .login-title {
        display: flex;
        flex-direction: column;
        align-items: center;

        &::before {
          content: ' ';
          display: block;
          width: 257px;
          height: 66px;
          background: url('../../assets/img/logo.png') no-repeat center;
          background-size: 100%;
        }

        .title-text {
          margin-top: 10px;
          font-size: 20px;
          color: #333;
        }
      }

      .login-form {
        margin-top: 40px;

        .form-remember {
          cursor: pointer;

          .remember-text {
            margin-left: 10px;
            color: #777;
          }
        }

        .form-login {
          width: 100%;
        }
      }
    }

    .login-footer {
      display: flex;
      align-items: flex-end;
      height: 160px;
      padding-bottom: 20px;

      .footer-desc {
        margin: 0;
        color: #999;

        > span {
          margin-left: 10px;
        }
      }
    }
  }
</style>
