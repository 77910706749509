import request, { requestMethods } from '../../lib/request.js';

// 登录
export const login = async data => {
  const url = '/login';

  return await request({
    url,
    method: requestMethods.POST,
    data,
  });
};
